.menuFrame {
  width: 60%;
  border: 1px solid;
  padding: 3px 4.5px;

  &__title {
    font-size: 18px;
    margin-bottom: 16px;
  }

  &__text {
    font-size: 16px;
    margin-bottom: 48px;
  }

  &__icon {
    margin-bottom: 54px;
  }

  &__btn {
    padding: 12px;
  }

  &__subTitle {
    font-size: 10px;
    margin-top: 5px;
  }

  &-italy {
    border-color: #141514;
    font-family: "Synerga Pro", serif;

    & .menuFrame__title,
    & .menuFrame__text,
    & .menuFrame__btn {
      font-family: inherit;
      color: #141514;
    }

    & .menuFrame__icon svg {
      color: #141514;
      fill: #141514;
    }

    & .menuFrame__btn {
      border-color: #141514;
    }

    & .menuFrame__subTitle {
      color: rgba(249, 246, 242, 0.65);
    }
  }

  &-asia {
    border-color: #dab077;
    font-family: "Fontin Sans CR", sans-serif;

    & .menuFrame__title,
    & .menuFrame__text,
    & .menuFrame__btn {
      font-family: inherit;
      color: #dab077;
    }

    & .menuFrame__icon svg {
      color: #dab077;
      fill: #dab077;
    }

    & .menuFrame__btn {
      border-color: #ffffff;
      color: #fff;
    }

    & .menuFrame__subTitle {
      color: #f9f6f2;
    }
  }

  &__sub {
    border: inherit;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 39px 23px 23px;
    color: #dab077;
    text-align: center;

    // & h3 {
    //   margin-top: 0;
    // }
  }
}

@media screen and (max-width: 768px) {
  .menuFrame {
    width: 65%;
    background-color: rgba(20, 21, 20, 0.45);
    &__sub {
      padding: 37px 25px 29px;
    }

    &__title {
      font-size: 16px;
    }

    &-italy {
      & .menuFrame__title,
      & .menuFrame__text {
        color: #ffffff;
      }

      & .menuFrame__icon svg {
        color: #fbfbfb;
        fill: #fbfbfb;
      }

      & .menuFrame__btn {
        color: #fff;
        border-color: #fff;
      }
    }
  }
}
