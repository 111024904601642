.nav {
  position: fixed;
  z-index: 10;
  top: 0px;
  left: 0;
  right: 0;

  &__wrapper {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 91%;
    max-width: 1313px;
    padding-top: 57px;
    margin: 0 auto;
    transition: width 0.3s ease;
  }

  &__menu {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__languageList {
    list-style: none;

    & li {
      text-align: center;
      padding: 0 5px;

      &:nth-last-child(n + 2) {
        margin-bottom: 5px;
      }
    }
  }

  &__language {
    text-decoration: none;
    font-size: 14px;
    font-weight: 400;
    line-height: 1;
    text-transform: uppercase;
    font-family: "Fontin Sans CR", sans-serif;
    color: #c4a394;

    &:hover,
    &:focus,
    &:active {
      text-decoration: underline;
    }
  }

  &__icon {
    position: relative;
    top: 0;
    width: 45px;
    display: block;
    height: auto;
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    transition: top 0.3s ease;

    &:hover,
    &:focus {
      opacity: 0.9;
    }

    &:active {
      top: 1px;
    }

    &-menu {
      color: #c4a394;
    }

    &-location {
      color: #f3f4f6;
    }
  }

  &__logo {
    position: absolute;
    top: 50vh;
    left: 50%;
    z-index: 1;
    transform: translate(-50%, -50%);
    color: #f9f6f2;
    text-align: center;
    pointer-events: none;

    & svg {
      width: 71px;
      height: 64px;
    }
    & p {
      display: none;
      width: 220px;
      font-size: 12px;
    }

    & span {
      display: inline-block;
    }
  }

  &.seesaw {
    & .nav__wrapper {
      width: 72%;
      max-width: 1080px;
    }

    & .nav__logo p {
      display: block;
    }

    &-asia {
      & .nav__wrapper {
        margin-left: 4.4%;
        margin-right: auto;
      }
      & .nav__icon {
        color: #c4a394;
      }

      & .nav__logo p {
        font-family: "Fontin Sans CR", sans-serif;
      }

      & .nav__language {
        font-family: "Fontin Sans CR", sans-serif;
        color: #c4a394;
      }
    }

    &-italy {
      & .nav__wrapper {
        margin-right: 4.4%;
        margin-left: auto;
      }
      & .nav__icon {
        color: black;
      }

      & .nav__logo {
        color: black;
      }

      & .nav__logo p {
        font-family: "Synerga Pro", serif;
      }

      & .nav__language {
        font-family: "Synerga Pro", serif;
        color: black;
      }
    }

    & .nav__logo {
      top: 98px;
      width: 100%;

      & p {
        margin-top: 62px;
        margin-left: auto;
        text-align: right;
      }
    }
  }

  &.nav-popup {
    & .nav__wrapper {
      width: 91%;
      max-width: 1313px;
    }
    &.onScroll {
      background-color: transparent;

      &__wrapper {
        width: 91%;
        max-width: 1313px;
        padding-top: 57px;
        margin: 0 auto;
      }
    }
    & .nav__icon {
      color: #c4a394;
    }
    & .nav__logo {
      opacity: 0.2;
    }
  }

  &.onScroll {
    background-color: rgba(0, 0, 0, 0.3);
    & .nav__wrapper {
      display: flex;
      width: 90%;
      max-width: 1300px;
      padding-top: 12px;
      padding-bottom: 12px;
    }
    & .nav__logo {
      position: static;
      top: unset;
      left: unset;
      transform: unset;
      display: flex;
      svg {
        display: none;
      }

      p {
        margin: 0 0 0 auto;
        color: #fff;
      }
    }

    & .nav__icon {
      color: #fff;
    }

    & .nav__icon-location {
      display: none;
    }
  }
}

@media screen and (max-width: 768px) {
  .nav {
    width: 88.26%;
    min-width: 331px;
    padding-top: 15px;
    margin: 0 auto;

    &__wrapper {
      margin: 0;
      padding: 0;
      width: 100%;
    }

    &__menu {
      flex-direction: row;
      align-items: center;
      margin-left: auto;
    }

    & .nav__icon-location {
      display: none;
    }

    & .nav__icon-menu {
      margin-left: auto;
    }

    &.seesaw {
      & .nav__wrapper {
        width: 100%;
        flex-direction: row-reverse;
        align-items: self-start;
      }

      & .nav__logo {
        position: static;
        margin: 0;
      }

      & .nav__logo p {
        display: none;
      }

      &-asia .nav__wrapper,
      &-italy .nav__wrapper {
        margin: 0 auto;
      }

      &-asia .nav__icon,
      &-asia .nav__logo {
        color: #c4a394;
      }

      &-italy {
        & .nav__icon,
        & .nav__language {
          color: #c4a394;
        }

        &.nav-popup .nav__icon {
          color: #c4a394;
        }

        & .nav__logo {
          color: black;
        }
      }

      & .nav__logo {
        top: 0;
        transform: unset;
        left: 0;
        width: auto;
      }
    }

    &.nav-popup {
      & .nav__wrapper {
        width: 100%;
      }

      & .nav__icon {
        color: #c4a394;
      }

      // & .nav__icon-location {
      //   display: block;
      // }
    }

    &.onScroll {
      padding: 0;
      margin: 0;
      width: 100%;

      & .nav__wrapper {
        width: 88.26%;
        min-width: 331px;
        margin: 0 auto;
      }
    }
  }
}
