.slider {
  &__container {
    position: relative;
    width: 100%;
    // max-height: 500px;
    padding-top: 56.25%;
    margin: auto;
    background-color: #fff;
    overflow: hidden;

    &::before,
    &::after {
      content: "";
      position: absolute;
      bottom: 12.3%;
      transform: translateY(-50%);
      z-index: 10;
      display: block;
      height: 1px;
      width: 45%;
      background-color: #eca86a;
    }

    &::before {
      left: 0;
    }

    &::after {
      right: 0;
    }

    &-asia {
      font-family: "Fontin Sans CR", sans-serif;

      & .slider__nav {
        font-family: inherit;
        color: #eca86a;
      }
    }
    &-italy {
      & .slider__title,
      & .slider__subTitle,
      & .slider__numbertext {
        font-family: "Synerga Pro", serif;
      }

      & .slider__numbertext,
      & .slider__arrow {
        color: #fafeff;
      }

      &::before,
      &::after {
        background-color: #fafeff;
      }
    }
  }

  &__content {
    position: absolute;
    top: 33px;
    left: 41px;
    z-index: 10;
    width: 200px;
    font-family: inherit;
  }

  &__title {
    padding-bottom: 26px;
    border-bottom: 1px solid #eca86a;
    font-family: inherit;
    font-size: 36px;
    line-height: 1;
    color: #f9f6f2;
  }

  &__hint {
    display: none;
    justify-content: space-between;
    align-items: center;
    margin-top: 18px;
  }

  &__subTitle {
    font-family: inherit;
    font-size: 14px;
    color: #eca86a;
  }

  &__slide {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    display: none;
    width: 100%;
  }

  &__img {
    display: block;
    width: 100%;
    height: auto;
  }

  &__nav {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 9.7%;
    transform: translateY(-50%);
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 120px;
    margin: 0 auto;
  }

  &__arrow {
    cursor: pointer;
    font-weight: bold;
    transition: 0.6s ease;
    user-select: none;

    &-prev:hover svg,
    &-next:hover svg,
    &-prev:focus svg,
    &-next:focus svg {
      opacity: 0.8;
    }
    &-prev:hover svg,
    &-next:hover svg,
    &-prev:focus svg,
    &-next:focus svg {
      opacity: 0.9;
    }

    &-next svg {
      transform-origin: center;
      transform: rotate(180deg);
    }
  }

  &__numbertext {
    color: #e8b688;
    font-size: 16px;
  }
}

@media screen and (max-width: 768px) {
  .slider {
    &__container::after,
    &__container::before {
      bottom: 14.2%;
      width: 30%;
    }

    &__content {
      top: 33px;
      left: 23px;
    }

    &__hint {
      display: flex;
    }

    &__title {
      font-size: 24px;
      padding-bottom: 16px;
    }

    &__nav {
      width: 100px;
    bottom: 8%;
    }

    &__arrow svg {
      width: 14px;
      height: auto;
    }

    &__numbertext {
      font-size: 14px;
    }
  }
}

.fade {
  -webkit-animation-name: fade;
  -webkit-animation-duration: 1.5s;
  animation-name: fade;
  animation-duration: 1.5s;
}

@-webkit-keyframes fade {
  from {
    opacity: 0.4;
  }
  to {
    opacity: 1;
  }
}

@keyframes fade {
  from {
    opacity: 0.4;
  }
  to {
    opacity: 1;
  }
}
