* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html {
  overflow-x: hidden;
  font-size: 1vw;
  font-family: "Stolzl";
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  height: 100vh;
}

body {
  padding: 0;
  margin: 0;
  overflow: hidden;
  height: 100%;
  width: 100%;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-weight: 300;
  color: #fdfffc;
  /* before asia/italy chosen */
}

@import "./preloader.scss";

@font-face {
  font-family: "Fontin Sans CR";
  src: local("Fontin Sans CR Regular"), local("FontinSansCR-Regular"),
    url("../fonts/fontinsanscrregular.woff2") format("woff2"),
    url("../fonts/fontinsanscrregular.woff") format("woff"),
    url("../fonts/fontinsanscrregular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Fontin Sans CR";
  src: local("Fontin Sans CR Italic"), local("FontinSansCR-Italic"),
    url("../fonts/fontinsanscritalic.woff2") format("woff2"),
    url("../fonts/fontinsanscritalic.woff") format("woff"),
    url("../fonts/fontinsanscritalic.ttf") format("truetype");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "Fontin Sans CR";
  src: local("Fontin Sans CR Bold"), local("FontinSansCR-Bold"),
    url("../fonts/fontinsanscrbold.woff2") format("woff2"),
    url("../fonts/fontinsanscrbold.woff") format("woff"),
    url("../fonts/fontinsanscrbold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Fontin Sans CR";
  src: local("Fontin Sans CR Bold Italic"), local("FontinSansCR-BoldItalic"),
    url("../fonts/fontinsanscrbolditalic.woff2") format("woff2"),
    url("../fonts/fontinsanscrbolditalic.woff") format("woff"),
    url("../fonts/fontinsanscrbolditalic.ttf") format("truetype");
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: "Synerga Pro";
  src: local("Synerga Pro Regular"), local("SynergaPro-Regular"),
    url("../fonts/Synergaproregular.woff2") format("woff2"),
    url("../fonts/Synergaproregular.woff") format("woff"),
    url("../fonts/Synergaproregular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

.mobile {
  display: none;
}

.desktop {
  display: initial;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Fontin Sans CR", sans-serif;
  font-weight: 700;
  letter-spacing: 0.05em;
  font-variant: small-caps;
}

.italy h1,
.italy h2,
.italy h3,
.italy h4,
.italy h5,
.italy h6,
.half.second h1,
.half.second h2,
.culture.italy h4 {
  font-family: "Synerga Pro", serif;
  font-weight: 400;
  letter-spacing: 0.05em;
  font-variant: unset;
}

p {
  font-family: "Fontin Sans CR";
  letter-spacing: 0.04em;
  font-weight: 400;
  line-height: 135%;
}

.poppins {
  font-family: "Poppins", sans-serif;
  font-variant: normal;
}

/* .encodesans{
  font-family: 'Encode Sans', sans-serif;
} */

/* .opensans{
  font-family: 'Open Sans', sans-serif;
} */

.libre {
  font-family: "Libre Baskerville", serif;
}

/* DOORS & SLIDER*/
#doors {
  height: 100vh;
  width: 100vw;
  display: flex;
  position: relative;
  overflow: hidden;
}

#doors .half {
  width: 50%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 1.3s ease-in-out;
}

.half__birdBg,
.half__title {
  display: none;
}

#doors .half.opened {
  position: relative;
  width: 85%;

  & .half__birdBg {
    position: absolute;
    display: block;
    top: -35px;
    z-index: 1;

    &-asia {
      color: #f3ebe0;
      mix-blend-mode: overlay;
    }

    &-italy {
      color: #eca86a;
      opacity: 0.45;
    }
  }

  & .half__title {
    position: absolute;
    top: 83px;
    display: block;
    text-align: center;
    font-size: 24px;
    line-height: 1.35;

    & span {
      display: block;
    }

    &-asia {
      width: 250px;
      color: #f9f6f2;
      mix-blend-mode: soft-light;
    }

    &-italy {
      width: 250px;
      color: #141514;
      mix-blend-mode: color-burn;
    }
  }

  &.second {
    background-position: center top;
    background-size: cover;
  }
}

@media screen and (max-width: 768px) {
  #doors .half.opened .half__birdBg {
    left: 82%;
    top: -18px;
    z-index: 0;
    transform-origin: center;
    transform: rotate(2deg) translateX(-50%);
  }
}

#doors .half.shrinked {
  width: 20%;
  cursor: pointer;
  z-index: 4;
  background-size: cover;

  &-italy {
    background-position: left -100px top;
    filter: sepia(0.35);
  }
  &-asia {
    background-position: center bottom;
    background-color: rgba(0, 0, 0, 0.5);
    background-blend-mode: multiply;

    & .half__birdBg,
    & .half__title,
    & .header__btn,
    & .gotoaction,
    & .darker,
    & .horizont,
    & .content,
    & .vertical {
      display: none;
    }
  }
}

.frame {
  position: absolute;
  height: 40%;
  width: 40%;
  z-index: 1;
}

.half.shrinked .frame {
  width: 15%;
}

.darker {
  position: absolute;
  width: 100%;
  height: 30%;
  background: #141514;
  opacity: 0.55;
  top: 25%;
}

.half.second .frame {
  border-color: #141514 !important;
}

.half.second.shrinked .frame {
  border-color: white !important;
}

.frame:nth-of-type(1) {
  top: 2vw;
  left: 2vw;
  border-left: 1px solid #dab077;
  border-top: 1px solid #dab077;
}

.frame:nth-of-type(2) {
  top: 2vw;
  right: 2vw;
  border-top: 1px solid #dab077;
  border-right: 1px solid #dab077;
}

.frame:nth-of-type(3) {
  left: 2vw;
  bottom: 2vw;
  border-left: 1px solid #dab077;
  border-bottom: 1px solid #dab077;
}

.frame:nth-of-type(4) {
  bottom: 2vw;
  right: 2vw;
  border-right: 1px solid #dab077;
  border-bottom: 1px solid #dab077;
}

.simetric {
  position: absolute;
  bottom: 2%;
  right: 50%;
  transform: translate(50%, 0);
  width: 5vw;
}

.gotoaction {
  width: 30vw;
  margin-bottom: 20%;
  margin-right: 15%;
  position: relative;
  z-index: 2;
}

.gotoaction p {
  font-size: 0.8rem;
  margin-right: 15%;
  line-height: 140%;
}

.gotoaction p.orange {
  color: #dab077;
  margin-top: 1vw;
}

// .gotoaction p.btn {
//   margin-top: 2vw;
// }

.gotoaction h1 {
  color: #dab077;
  font-size: 2.8rem;
}

// p.btn {
//   font-size: 0.75rem;
//   margin-top: 10%;
//   border: 1px solid #eca86a;
//   width: 8vw;
//   height: 2.2vw;
//   display: flex;
//   color: #eca86a;
//   justify-content: center;
//   align-items: center;
//   cursor: pointer;
//   transition: border 0.3s ease-in-out;
//   margin-top: 5vw;
// }

// .btn:active {
//   transform: translate(0, 1px);
// }

// .gotoaction p.btn:hover {
//   border: 1px solid #f3c9a1;
// }

// .gotoaction p.btn:active {
//   transform: scale(0.99);
// }

// .second .gotoaction p.btn {
//   border: 1px solid #f9f6f2;
//   color: #f9f6f2;
// }

.second .gotoaction h1 {
  color: #dab077;
}

.horizont {
  position: absolute;
  top: 50%;
  left: 0;
  width: 50vw;
  object-fit: contain;
}

.flower {
  position: absolute;
  opacity: 0;
  z-index: -1;
  transition: opacity 0.3s ease-in-out;
}

.flower.visible {
  position: absolute;
  opacity: 1;
  z-index: 20;
}

.flower.first {
  position: absolute;
  left: 4%;
  width: 2vw;
  top: 50%;
  transform: translate(0, -50%);
}

.flower.second {
  position: absolute;
  left: unset;
  right: 4%;
  width: 2vw;
  top: 50%;
  transform: translate(0, -50%);
}

.half.second .flower.first {
  transform: translate(0, -50%) rotate(90deg);
  width: 4vw;
  left: 3%;
}

.half.second .flower.second {
  transform: translate(0, -50%) rotate(-90deg);
  width: 4vw;
  right: 3%;
}

.dish-bg {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  position: absolute;
  z-index: -20;
}

.dish-bg.visible {
  opacity: 1;
  z-index: 0;
  width: 100%;
  object-fit: cover;
  top: 25%;
  height: 80vh;
}

.switch-logo {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: inline-block;
  padding-bottom: 10px;
  font-size: 16px;
  line-height: 1;
  text-transform: uppercase;
  border-bottom: 2px solid;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;

  &_italy {
    font-family: "Synerga Pro", serif;
    color: #fafeff;
    border-color: #fafeff;
  }
  &_asia {
    font-family: "Fontin Sans CR", sans-serif;
    color: #dab077;
    border-color: #dab077;
  }
  &_sm {
    padding-bottom: 6px;
    border-bottom: 1px solid;
  }
}

.switch-logo.visible {
  opacity: 1;
}

.vertical {
  position: absolute;
  height: 100%;
  bottom: -2vw;
  right: 6vw;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.visible {
  opacity: 1 !important;
}

.burger,
.half .pin {
  position: absolute;
  width: 2.5vw;
  top: 8%;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  cursor: pointer;
  z-index: 2;
}

.doorlogo {
  position: absolute;
  width: 6vw;
  top: 1vw;
  opacity: 0;

  &-asia {
    color: #b59586;
  }

  &-italy {
    color: #141514;
  }
}

.half.second .doorlogo {
  width: 15vw;
}

.burger {
  left: 7%;
  z-index: 99999999999;
}

.burger:active,
.discover:active {
  transform: translate(0, 2px);
}

.half .pin {
  right: 7%;
}

.address {
  position: absolute;
  top: 18%;
  right: 7%;
  text-align: right;
  font-size: 0.8rem;
  color: #f9f6f2;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.half.second .address {
  color: #000000;
}

.half .socials {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  top: 28%;
  position: absolute;
  z-index: 2;
  cursor: pointer;
}

.half .socials i {
  margin: 0 0.5vw;
  font-size: 1.1rem;
  color: #eca86a;
  cursor: pointer;
}

.half.second .socials i {
  color: #f0f1f3;
}

.discover {
  position: absolute;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  z-index: 20;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}

.discover p {
  color: rgba(236, 168, 106, 0.65);
  font-size: 0.8rem;
  font-weight: 500;
}

.half.second .discover p {
  color: rgba(20, 21, 20, 0.65);
}

.discover img {
  height: 5vw;
}

/* POPUP */

#popup.visible {
  opacity: 1;
  z-index: 5;
}

.popup {
  display: flex;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(17, 0, 5, 0.85);
  opacity: 0;
  z-index: -1;
  transition: all 0.6s ease-in-out;

  &::after,
  &::before {
    content: "";
    position: absolute;
    left: 50%;

    display: block;
    width: 1px;
    height: 330px;

    background-color: #fff;
  }

  &__icon {
    position: absolute;
    top: 0;
    display: none;
    width: 45px;
    height: auto;
    margin-left: 50px;
    color: #c4a394;
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    transition: top 0.3s ease;

    &:hover,
    &:focus {
      opacity: 0.9;
    }

    &:active {
      top: 1px;
    }
  }

  &::after {
    top: 0;
    margin-top: 30px;
  }

  &::before {
    bottom: 0;
    margin-bottom: 30px;
  }

  &__half {
    display: flex;
    width: 50%;
  }

  &__pdfs {
    margin-top: 275px;
  }

  &__footer {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 50%;
  }

  &__copyright {
    margin-top: 27px;
    margin-bottom: 30px;
    text-align: center;
    font-size: 12px;
    color: #b4b3a5;
    font-family: sans-serif;
  }
}

.pdfs {
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: column;

  &__container {
    position: relative;
    display: flex;
    justify-content: space-between;
    width: 62%;
    margin: 55px 0 54px;
  }

  &__link {
    text-decoration: none;
    color: #c4a394;
    font-family: "Synerga Pro";
    font-size: 1.4rem;
    border:none;
    background-color: transparent;
    outline: none;
  }

  &__icon {
    position: absolute;
    width: 32px;
    height: 41px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.mapper {
  padding-top: 65px;
  padding-bottom: 81px;

  &__wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 420px;
    margin: 0 auto;
  }

  &__addr {
    text-transform: uppercase;
    font-size: 1rem;
    line-height: 1.35;
    color: #b4b3a5;
  }

  &__map {
    height: 262px;
    width: 100%;
    margin: 39px 0 74px;
  }

  &__container {
    margin-left: 9px;
  }

  &__workTime {
    color: #dab077;
    font-size: 14px;
    line-height: 1.35;
    margin-bottom: 14px;
  }

  &__time {
    font-size: 16px;
    color: #f9f6f2;
  }

  &__time:nth-last-child(n + 2) {
    margin-bottom: 33px;
  }

  &__bot {
    position: relative;
    display: block;
    margin-top: auto;
    text-align: center;
    text-decoration: underline;
    font-size: 16px;
    color: #c4a394;
    line-height: 1.35;

    &::after {
      content: "";
      position: absolute;
      bottom: -69px;
      left: 50%;
      transform: translateX(-50%);
      display: block;
      width: 85px;
      height: 38px;
      background-image: url(../img/emblem.svg);
      background-repeat: no-repeat;
      background-size: cover;
      opacity: 0.1;
    }
  }
}

.socials {
  display: flex;
  width: 350px;
  margin: 0 auto;
  text-align: center;

  &__asia,
  &__italy {
    display: flex;
    flex-direction: column;
  }
  &__link {
    display: inline-block;
    width: 55px;
    padding: 31px 0 10px;
    text-decoration: none;
    color: #c4a394;
    font-size: 10px;
    background-repeat: no-repeat;

    &-fb {
      background-image: url("../img/soc-facebook.svg");
      margin-bottom: 23px;
    }
    &-insta {
      background-image: url("../img/soc-insta.svg");
    }
    &-tripadvisor {
      position: relative;
      top: 8px;
      background-image: url("../img/soc-tripadvisor.svg");
      align-self: flex-end;
      flex-shrink: 0;
      margin: 0 auto;
    }
  }
}

.header__btn {
  display: none;
  position: absolute;
  top: 37px;
  z-index: 10;
  width: 150px;
  font-size: 14px;
  text-transform: uppercase;
  border: none;
  background-color: transparent;

  &::before {
    content: "";
    position: absolute;
    top: -60px;
    left: 0;
    right: 0;
    display: block;
    margin: 0 auto;
    width: 1px;
    height: 50px;
  }

  &-asia {
    font-family: "Fontin Sans CR", sans-serif;
    color: rgba(236, 168, 106, 0.65);

    &::before {
      background-color: #eca86a;
    }
  }

  &-italy {
    font-family: "Synerga Pro", serif;
    color: rgba(20, 21, 20, 0.65);

    &::before {
      background-color: black;
    }
  }

  &-active {
    display: block;
  }
}

.footer {
  position: relative;
  width: 100%;

  .footer__soc li a {
    display: block;
  }

  &-italy {
    background-color: black;
    background-position: center -115px;
    background-size: cover;
    background-repeat: no-repeat;
    font-family: "Synerga Pro", serif;

    & .footer__time-italy {
      display: inline-block;
    }

    & .footer__soc-italy {
      display: flex;
    }
  }

  &-asia {
    font-family: "Fontin Sans CR", sans-serif;
    &::after {
      content: url("../img/footer-dragon.svg");
      position: absolute;
      bottom: -25px;
      z-index: 0;
      right: 0;
      display: block;
      mix-blend-mode: overlay;
    }
    & .footer__time-asia {
      display: inline-block;
    }

    & .footer__soc-asia {
      display: flex;
    }

    & .footer__middle,
    & .footer__right {
      position: relative;
      z-index: 10;
    }
  }

  &__wrapper {
    display: flex;
    justify-content: space-between;
    width: 80%;
    max-width: 1200px;
    margin: 0 auto;
    padding-top: 24px;
    padding-bottom: 34px;
    font-family: inherit;
  }

  &__logo {
    display: block;
    width: 64px;
    height: 114px;
    margin-bottom: 14px;
  }

  &__copyright {
    width: 100%;
    font-size: 12px;
    font-family: inherit;
  }

  &__locationPin {
    display: block;
    width: 55px;
    height: auto;
    margin: 0 auto;
    background-color: transparent;
    border: none;
  }

  &__middle {
    display: flex;
    flex-direction: column;
    padding: 0 5%;
    font-family: inherit;
  }

  &__info {
    width: 210px;
    margin-top: auto;
    font-size: 14px;
    color: #b4b3a5;
    font-family: inherit;

    & > span:nth-last-of-type(n + 2) {
      margin-bottom: 8px;
    }
  }

  &__time,
  &__addr,
  &__tel {
    display: inline-block;
    width: 100%;
  }

  &__time {
    display: none;
  }

  &__tel a {
    color: #b4b3a5;
  }

  &__right {
    display: flex;
    flex-direction: column;
    width: 410px;
    font-family: inherit;
  }

  &__soc {
    display: none;
    justify-content: space-between;
    width: 67%;
    margin-left: auto;
    list-style: none;
  }

  &__links {
    display: flex;
    justify-content: space-between;
    margin-top: auto;
    font-size: 12px;
    color: #b4b3a5;
    font-family: inherit;
  }

  &__btn {
    background-color: transparent;
    border: none;
    text-decoration: underline;
    color: inherit;
    font-family: inherit;
  }

  &__link {
    text-decoration: none;
    color: inherit;
    font-family: inherit;
  }
}

// mid
.mid {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  // display: flex;
  // flex-direction: column;
  // align-items: center;

  & svg {
    // width: 6vw;
    margin-bottom: -1vw;
  }
}

// .mid p {
//   font-size: 0.8rem;
// }

// .mid .phone {
//   margin-top: 0.7vw;
// }

// .mid p {
//   display: none;
// }

// .mid .logo {
//   width: 50vw;
// }
/* CONTENT UNDERNEATH*/
// #doors .half .content {
//   left: 0;
// }

// #doors .half .content.visible {
//   z-index: 20;
//   opacity: 1;
// }

// #doors .half .content {
//   margin-left: unset;
// }

// .half.second .info-block p.btn {
//   color: #f0f1f3;
//   border: 1px solid #f0f1f3;
// }

// .info-block {
//   width: 40vw;
//   /* margin-left: 2vw; */
//   margin-right: auto;
// }

// .info-block h2 {
//   font-size: 2.4rem;
//   margin-bottom: 0.5vw;
// }

// .info-block p {
//   /* font-size: .8rem; */
// }

// #popup .half {
//   height: 100%;
//   width: 50%;
//   position: relative;
// }

// #popup .half.mapper {
//   padding: 5% 0 5% 10%;
// }

// #popup p:not(.addr) {
//   font-size: 0.9rem;
// }

// #popup .bot {
//   position: absolute;
//   width: 100%;
//   text-align: center;
//   text-decoration: underline;
//   bottom: 3vw;
//   left: 0;
//   color: #c4a394;
//   cursor: pointer;
// }

// #popup #map

// #popup .mapper .yellow {
//   color: #dab077;
//   margin: 1vw 0;
// }

// #popup .socials img {
//   width: 3vw;
// }

// #popup .socials img.topper {
//   position: absolute;
//   left: 50%;
//   transform: translate(-50%, -150%);
// }

// #popup .copyright {
//   position: absolute;
//   bottom: 1vw;
//   width: 100%;
//   text-align: center;
//   font-family: "Fontin Sans CR";
//   font-size: 0.9rem;
//   color: #b4b3a5;
//   font-variant: small-caps;
// }

// #popup .line {
//   position: absolute;
//   height: 40%;
//   left: 50%;
//   border-left: 1px solid #f9f6f2;
// }

// #popup .line.upper {
//   top: 5%;
// }

// #popup .line.lower {
//   bottom: 5%;
// }

/* MIDDLE ROWS */
.middle {
  height: 65vw;
  display: none;
}

.row {
  height: 50%;
  display: flex;
  position: relative;
}

.row .hall {
  width: 48%;
  background-size: cover;
  background-position: center;
}

.row .about {
  width: 40%;
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 7%;
  text-align: center;
  color: #dab077;
}

.middle.italy .row .about {
  color: #000000;
}

.row .about p {
  margin-bottom: auto;
}

.row .about img {
  width: 8vw;
  margin-top: 15%;
}

.row .about h3 {
  font-size: 1.5rem;
  margin: 1vw 0;
}

.switcher {
  width: 12%;
  background-size: cover;
  background-position: center;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.italy .switcher {
  justify-content: center;
}

.switcher .fenixlogo {
  width: 5vw;
  position: relative;
}

.switcher .smallframe {
  position: absolute;
  object-fit: contain;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 92%;
}

.switcher .switch-logo,
.switcher .simetric {
  position: initial;
  opacity: 1;
  transform: unset;
}

.switcher .simetric {
  width: 2vw;
  position: relative;
  bottom: -1vw;
  right: unset;
}

.switcher img:not(.smallframe) {
  height: 5vw;
  object-fit: contain;
}

.row .horizontal {
  position: absolute;
  bottom: 10%;
  right: 12%;
  width: 70%;
}

.middle.italy .row .horizontal {
  right: unset;
  left: 12%;
}

.row .menu-block {
  width: 33%;
  background-size: cover;
  background-position: center;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  // color: #dab077;
  // text-align: center;
}

.italy .row .menu-block {
  color: #141514;
}

// .menu-block h3 {
//   font-size: 1.3rem;
//   font-weight: normal;
//   margin: 2vw 0 1vw;
// }

// .menu-block .book {
//   width: 4vw;
//   margin: 3vw 0;
// }

// .updated {
//   font-size: 0.7rem;
//   margin-top: 0.5vw;
//   opacity: 0.65;
//   letter-spacing: 0.23em;
//   color: white;
// }

// .menu-block .btn {
//   margin: 0;
//   z-index: 9999999999999999;
//   border: 1px solid #ffffff;
//   color: #ffffff;
// }

// .italy .menu-block .btn {
//   color: #141514;
//   border: 1px solid #141514;
// }

.row .plate {
  width: 55%;
  background-size: cover;
  background-position: center;
  position: relative;
  // padding: 3%;
}

.row .menuframe {
  position: absolute;
  left: 50%;
  top: 55%;
  transform: translate(-50%, -50%);
}

.row .menuframe:nth-child(1) {
  width: 55%;
}

.row .menuframe:nth-child(2) {
  width: 53%;
}

.plate h3 {
  font-size: 2.4rem;
}

.plate .fadeline {
  width: 50%;
  margin: 0.8vw 0;
}

.plate p {
  width: 65%;
}

.plate .goat {
  position: absolute;
  top: 50%;
  right: 2%;
  transform: translate(0, -50%);
  width: 3vw;
}

/* CULTURE */
.culture {
  height: 60vw;
  background-position: center;
  background-size: cover;
  position: relative;
}

.culture .stripe {
  position: absolute;
  width: 100%;
  object-fit: contain;
}

.culture .stripe:nth-child(1) {
  top: 23%;
}

.culture .stripe:nth-child(2) {
  bottom: 23%;
}

.culture .row {
  display: flex;
  height: 50%;
  justify-content: space-around;
  padding: 0 10%;
  color: #f3ebe0;
}

.culture .half {
  width: 50%;
}

.culture .row:not(.second) .half:not(.img) {
  padding-top: 10%;
}

.culture .row:not(.second) .half.img img {
  margin-top: 15%;
}

.culture h2 {
  font-size: 2rem;
  line-height: 85%;
  margin: 1vw 0 3vw;
  color: #dab077;
}

// .culture.italy h2 {
//   font-size: 2rem;
// }

.culture p {
  width: 85%;
}

.culture h4 {
  font-size: 1.4rem;
}

.culture .half.img img {
  width: 100%;
  padding-right: 15%;
}

.culture .second .half.img img {
  width: 100%;
  padding-right: 0;
  padding-left: 15%;
}

/* SERVICES */
.services {
  height: 55vw;
  display: flex;
  position: relative;
}

.services .blocks {
  display: flex;
  flex-direction: column;
  position: relative;
}

.services .blocks .row {
  display: flex;
}

.services .blocks .row .left {
  width: 33%;
  background-position: center;
  background-size: cover;
  padding: 2vw;
  position: relative;
  color: #dab077;
}

.services.italy .blocks .row .left {
  color: #141514;
}

.services h2 {
  line-height: 100%;
  margin-bottom: 1.5vw;
}

.services .blocks .row .left img {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 30%;
}

.services .blocks .row .colorful {
  width: 25%;
  background-color: #1a7f8e;
  padding: 2vw;
}

// .services .blocks .row .colorful .btn {
//   margin-left: auto;
//   margin-right: auto;
//   color: #f9f6f2;
//   width: 12vw;
//   margin-top: 4vw;
// }

// .services .blocks .row .colorful .updated {
//   text-align: center;
// }

.services .blocks .row .place {
  flex-basis: 100%;
  background-position: center;
  background-size: cover;
  padding: 2vw;
  position: relative;
}

.services .blocks .row .place p.middler {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  color: rgba(236, 168, 106, 0.65);
  font-size: 1.4rem;
}

.services .blocks .row .place p:not(.middler) {
  width: 56%;
}

.services.italy .blocks .row .colorful {
  width: 25%;
  background-color: #a41838;
}

.services .blocks .row .right {
  width: 67%;
  background-position: center;
  background-size: cover;
}

.services .blocks .row:nth-child(1) {
  height: 60%;
}

.services .blocks .row:nth-child(2) {
  height: 40%;
}

/* FOOTER */

footer {
  // height: 15vw;
  background-position: center;
  background-size: cover;
}

.container {
  width: 85%;
  margin: 0 auto;
}

footer .imgrow {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

footer .pin,
footer .socials img {
  width: 3vw;
}

// footer .socials {
//   width: 18vw;
// }

footer .socials img {
  margin: 0 1vw;
  object-fit: contain;
}

footer .logo {
  width: 18vw;
  height: 7vw;
  object-fit: contain;
  margin-top: 1vw;
}

footer .container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

footer .textrow {
  color: #b4b3a5;
  font-size: 0.8rem;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 2vw;
  line-height: 135%;
}

footer .copyright {
  width: 28vw;
}

footer .backontop {
  text-decoration: underline;
  cursor: pointer;
}

/*MOBILE VERSION*/
@media screen and (max-width: 768px) {
  #popup .line {
    display: none;
  }

  .mobile {
    display: initial;
  }

  .desktop {
    display: none;
  }

  #doors {
    flex-direction: column;
  }

  #doors .half {
    width: 100%;
    height: 100%;
  }

  #doors .half:nth-of-type(1) {
    background-position: 65% 65%;
    background-size: 180%;
  }

  .gotoaction {
    text-align: center;
    margin: 0;
    width: 100%;
    margin-bottom: 15%;
  }

  .burger.mobile {
    width: 12vw;
    opacity: 1;
    top: 5vw;
    right: 5vw;
    left: unset;
    position: fixed;
  }

  .gotoaction .orange {
    display: none;
  }

  // .gotoaction .btn {
  //   width: 40vw;
  //   height: 40vw;
  //   border-radius: 50%;
  //   position: absolute;
  //   left: 50%;
  //   top: 65%;
  //   transform: translate(-50%, -50%);
  //   margin: unset !important;
  //   color: #f9f6f2;
  //   border: none !important;
  // }

  // .gotoaction .btn:active {
  //   transform: translate(-50%, -50%) !important;
  // }

  // .half.first .gotoaction .btn {
  //   background: rgba(236, 168, 106, 0.18);
  // }

  // .half.second .gotoaction .btn {
  //   background: rgba(249, 246, 242, 0.18);
  // }

  .gotoaction h1 {
    font-size: 7rem;
    margin-top: 6vw;
  }

  .gotoaction p {
    font-size: 3.5rem;
    margin-right: 15%;
    line-height: 135%;
    text-align: left;
    margin-left: 17%;
    color: #dab077;
  }

  #doors .gotoaction p {
    margin-left: 22%;
    margin-top: 30%;
  }

  .darker {
    height: 100%;
    top: unset;
  }

  .frame {
    display: none;
    pointer-events: none;
  }

  #doors .half.shrinked {
    display: none;
  }

  #doors .half.opened {
    width: 100%;
  }

  #doors .half:nth-of-type(1) {
    background-position: center top;
    background-size: cover;
  }

  .burger:not(.mobile) {
    display: none;
  }

  .doorlogo {
    position: absolute;
    width: 20vw;
    top: 5vw;
    opacity: 0;
    left: 5vw;
  }

  .half .socials {
    display: none;
  }

  .info-block {
    width: 90%;
    margin: 0 auto;
  }

  .horizont {
    width: 195%;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-variant: unset;
  }

  .info-block h2 {
    font-size: 7rem;
    margin-bottom: 2vw;
  }

  .info-block p {
    font-size: 4rem;
  }

  // p.btn {
  //   width: 35vw;
  //   height: 10vw;
  //   margin-top: 7vw;
  // }

  .vertical {
    display: none;
  }

  .discover p {
    font-size: 4rem;
  }

  .discover img {
    height: 20vw;
    margin-top: 2vw;
  }

  .address {
    display: none;
  }

  .switcher {
    display: none;
  }

  .row {
    height: unset;
    flex-direction: column;
  }

  .middle .row.second {
    flex-direction: column-reverse;
  }

  .middle {
    height: unset;
  }

  .row .hall {
    width: 100%;
    height: 80vw;
  }

  .row .about {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 28px 38px 31px;
  }

  .row .about img,
  .row .horizontal {
    display: none;
  }

  .row .about p {
    margin-bottom: unset;
  }

  .row .about h3 {
    font-size: 7rem;
  }

  .row .about p {
    margin-bottom: unset;
    font-size: 4rem;
  }

  .row .plate {
    width: 100%;
    height: 125vw;
    padding: 5%;
  }

  .plate .fadeline {
    width: 100%;
    margin: 2vw 0;
  }

  .plate h3 {
    font-size: 7rem;
  }

  .plate p {
    width: 90%;
    font-size: 4rem;
  }

  .row .menu-block {
    width: 100%;
    // height: 60vw;
    height: 95vw;
    margin-top: -20%;
  }

  .row .menuframe {
    position: absolute;
    left: 50%;
    top: 10%;
    transform: translate(-50%, -50%);
    z-index: 99;
  }

  // .menu-block h3 {
  //   font-size: 4rem;
  //   font-weight: normal;
  //   margin: 2vw 0 1vw;
  // }

  .menu-block > * {
    top: -40%;
    position: relative;
    z-index: 9;
  }

  // .menu-block p {
  //   font-size: 3.5rem;
  // }

  // .menu-block .updated {
  //   font-size: 2.5rem;
  // }

  // .menu-block .book {
  //   width: 14vw;
  //   margin: 9vw 0;
  // }

  .culture {
    height: unset;
  }

  .culture .half.img img {
    margin: unset;
  }

  .culture .half {
    width: 100%;
  }

  .culture .stripe {
    position: absolute;
    width: 100%;
    object-fit: cover;
    height: 25vw;
    object-position: right;
  }

  .culture h4,
  .culture p {
    font-size: 3.5rem;
  }

  .culture p {
    width: 95%;
  }

  .culture h4 {
    width: 80%;
    margin: 2vw 0;
  }

  .culture h2 {
    font-size: 6rem;
    line-height: 100%;
    margin: 3vw 0;
    color: #dab077;
  }

  .culture .row {
    padding: 0 5%;
  }

  .culture .row.second {
    flex-direction: column-reverse;
    margin-top: 15vw;
    padding-bottom: 15vw;
  }

  .culture .row:not(.second) .half:not(.img) {
    padding-top: 2vw;
  }

  .culture .stripe:nth-child(2) {
    bottom: 18%;
  }

  .services {
    height: unset;
  }

  .services .blocks .row:nth-child(1) {
    flex-direction: column-reverse;
  }

  .services .blocks .row .right {
    width: 100%;
    padding: 5vw;
    height: 220vw;
  }

  .services .blocks .row:nth-child(2) {
    flex-direction: row;
  }

  .services .blocks .row .place p:not(.middler) {
    display: none;
  }

  .services .blocks .row .place {
    padding: 5vw;
  }

  .services .blocks {
    width: 100%;
  }

  .services h2 {
    font-size: 5rem;
  }

  .services .blocks .row .place {
    padding: 2vw;
    max-width: 33%;
  }

  .services .blocks .row .place:nth-child(2) {
    display: flex;
    align-items: center;
  }

  .services .blocks .row .place:nth-child(3) h2 {
    position: absolute;
    bottom: 2vw;
    left: -30%;
    white-space: nowrap;
  }

  // footer {
  //   height: 80vw;
  // }
}

.btn {
  padding-top: 12px;
  padding-bottom: 12px;
  font-size: 12px;
  line-height: 1;
  text-transform: uppercase;
  text-decoration: none;
  color: #fff;
  border: 1px solid transparent;
  background-color: transparent;
  cursor: pointer;
  outline: none;

  &-md {
    padding-left: 35px;
    padding-right: 35px;
  }

  &-sm {
    padding-left: 12px;
    padding-right: 12px;
  }

  &-light {
    border-color: #f0f1f3;
    color: #f0f1f3;

    &:active {
      background-color: #d4d4d4;
      color: #080808;
    }

    &:hover,
    &:focus {
      background-color: #f0f1f3;
      color: #141514;
    }
  }

  &-dark {
    border-color: #eca86a;
    color: #eca86a;

    &:active {
      background-color: #ecc187;
      color: #0e0e0e;
    }

    &:hover,
    &:focus {
      background-color: #dab077;
      color: #333;
    }
  }
}

.content {
  position: absolute;
  top: 27%;

  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  margin-right: auto;
  background-position: center;
  background-size: inherit;
  background-repeat: no-repeat;
  background-color: #fff;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;

  &__soc {
    display: flex;
    width: 100px;
    margin-top: 23px;
    margin-bottom: 86px;
    justify-content: space-between;
    align-items: center;
    list-style: none;

    &-asia .content__link a {
      color: #eca86a;
    }
    &-italy .content__link a {
      color: black;
    }
  }

  &__link a {
    font-size: 0;
    text-decoration: none;
  }

  &__wrapper {
    display: flex;
    width: 100%;
    padding-left: 146px;
    padding-top: 34px;
    padding-bottom: 31px;
    background-color: rgba(20, 21, 20, 0.35);
  }

  &__btn {
    position: absolute;
    top: 57%;
    transform: translateY(-50%);
    margin-top: 140px;
    margin-bottom: 50px;

    &-asia {
      color: rgba(236, 168, 106, 0.65);
      &::after {
        background-color: #eca86a;
      }

      &:focus,
      &:hover {
        color: rgba(236, 168, 106, 0.8);
      }

      &:active {
        color: rgba(236, 168, 106, 1);
      }
    }

    &-italy {
      color: rgba(20, 21, 20, 0.65);
      &::after {
        background-color: #141514;
      }

      &:focus,
      &:hover {
        color: rgba(20, 21, 20, 0.8);
      }

      &:active {
        color: rgba(20, 21, 20, 1);
      }
    }

    &::after {
      content: "";
      position: absolute;
      top: 30px;
      left: 50%;
      transform: translateX(-50%);
      width: 1px;
      height: 60px;
    }
  }
}

@media screen and (max-width: 768px) {
  .content__btn {
    top: 40%;
  }
}

.info {
  width: 560px;

  &__title {
    font-size: 36px;
    line-height: 1.35;
    color: #f3f4f6;
  }

  &__text {
    font-size: 16px;
    line-height: 1.25;
    color: #f9f6f2;
  }

  &__btn {
    margin: 30px 0;
  }
}

.half__btn {
  padding: 10px 25px;
  margin-top: 10px;
}

// rii media 768px

@media screen and (max-width: 768px) {
  .second.opened .burger,
  .second.opened .pin,
  .first.opened .pin,
  .first.opened .burger {
    display: none;
  }

  //MENU

  .popup {
    flex-direction: column;

    &__half {
      width: 100%;
    }

    &::after,
    &::before {
      width: 130px;
      height: 1px;
      top: 40%;
      left: unset;
      margin: 0;
    }

    &::after {
      right: 10%;
    }

    &::before {
      left: 10%;
    }

    &__icon {
      display: block;
    }

    &__pdfs {
      margin-top: 89px;
    }

    &__footer {
      width: 100%;
    }

    &__copyright {
      width: 281px;
      margin-left: auto;
      margin-right: auto;
    }
  }

  .pdfs {
    &__link {
      font-size: 16px;
      border: none;
      background-color: transparent;
    }

    &__container {
      width: 313px;
      margin: 35px;
    }

    &__icon {
      display: none;
    }
  }

  .socials {
    width: 300px;
  }

  .mapper {
    margin-top: 91px;
    padding: 0;

    &__addr {
      width: 277px;
      padding-left: 55px;
      margin: 0 auto;
      font-size: 16px;
      text-transform: initial;
    }

    &__map {
      display: none;
    }

    &__container {
      display: none;
    }

    &__bot {
      margin-top: 25px;
      text-transform: lowercase;
      &::after {
        display: none;
      }
    }
  }

  //FOOTER
  .footer {
    &-italy {
      background-color: white;
      background-position: 0px center;
      background-blend-mode: hard-light;
    }

    &-asia::after {
      display: none;
    }

    &__wrapper {
      flex-direction: column;
      padding: 37px 0 33px;
      align-items: center;
    }

    &__left {
      order: 1;
    }

    &__logo {
      display: none;
    }

    &__copyright {
      text-align: center;
    }

    &__middle {
      position: relative;
      padding: 0;
    }

    &__locationPin {
      position: absolute;
      top: -15px;
      left: 0;
      width: 45px;
      margin: 0;
    }

    &__info {
      display: flex;
      flex-direction: column;
      width: auto;
      text-align: center;
      font-size: 16px;
    }

    &__addr {
      order: -1;
      margin-bottom: 39px;
      padding-left: 55px;
    }

    &__time {
      color: #c4a394;
      margin-bottom: 26px;
    }

    &__tel {
      color: #b4b3a5;
      margin-bottom: 32px;

      & a {
        color: #b4b3a5;
      }
    }

    &__right {
      flex-direction: column-reverse;
      align-items: center;
    }

    &__soc {
      margin: 0;
      margin-bottom: 30px;
    }

    &__links {
      margin-bottom: 34px;
    }

    &__link {
      display: inline-block;
      margin: 0 auto;
      font-size: 16px;
      color: #c4a394;
      text-decoration: underline;
    }

    &__btn {
      display: none;
    }
  }

  // Dors BTN
  #doors .half {
    position: relative;
  }
  .half__btn {
    position: absolute;
    top: 23%;
    left: 46%;
    transform: translate(-50%);
    width: 150px;
    height: 150px;
    border-radius: 50%;
    color: #f9f6f2;
    border: none;
    &.btn-light {
      background-color: rgba(249, 246, 242, 0.18);
    }
    &.btn-dark {
      background-color: rgba(236, 168, 106, 0.18);
    }
  }

  //ITALY MEDIA
  .content {
    &__soc {
      display: none;
    }
    &__wrapper {
      margin-top: 36px;
      padding: 0;
      padding-left: 23px;
      padding-right: 19px;
    }
    &__info {
      margin-top: 23px;
    }
    &__btn {
      margin-top: 170px;
    }
  }

  .info {
    &__title {
      width: 302px;
      font-size: 24px;
      line-height: 1.05;
    }
    &__text {
      margin-top: 14px;
      font-size: 14px;
    }
    &__btn {
      margin-top: 55px;
    }
  }

  // MIDDLE
  // ROW 1
  #middle .row.first {
    position: relative;
    height: 541px;
  }

  #middle .about,
  #middle .hall {
    position: absolute;
  }

  #middle .hall {
    top: 0;
  }

  #middle .about {
    top: 300px;
  }

  #middle .menu-block {
    background-image: unset !important;
  }

  #middle .row.second {
    position: relative;
    flex-direction: unset;
    height: 697px;
    background-image: url("../img/lightbg.jpg");
  }

  #middle .plate,
  #middle .menu-block {
    position: absolute;
  }

  #middle .plate {
    top: 0;
  }

  #middle .row .menuframe {
    z-index: 0;
  }
  #middle .menu-block {
    top: 540px;
    color: #f3f3f6;

    & > img {
      background-color: rgba(20, 21, 20, 0.35);
    }
  }

  #services .menu-block {
    color: #f3f3f6;
    & > img {
      background-color: rgba(20, 21, 20, 0.35);
      z-index: 1;
    }
  }

  //ASIA
  #servicesAsia {
    background-image: url("../img/bg-gradient-asia.jpg");
  }
  #middleAsia {
    background-image: url("../img/bg-gradient-asia.jpg");
  }
}

.noScroll {
  height: 100vh;
  overflow-y: hidden;
}

@import "./menu-header.scss";
@import "./menuFrame.scss";
@import "./slider.scss";
